import { Outlet } from "react-router-dom";
import TourPackageList from "./TourPackageList";
import TourPackageForm from "./TourPackageForm";
import TourPackageView from "./TourPackageView";

export const adminTourPackagesRoutes = [
  {
    path: "tour-packages",
    element: Outlet,
    children: [
      {
        path: "",
        element: TourPackageList,
      },
      {
        path: "add",
        element: TourPackageForm,
      },
      {
        path: "edit/:id",
        element: TourPackageForm,
      },
      {
        path: "view/:id",
        element: TourPackageView,
      },
    ],
  },
];
