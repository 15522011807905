import { Alert, Button, Card, CardActions, CardContent, CardHeader, Dialog, MenuItem } from '@mui/material';
import { BookingStatus } from 'components/BookingStatus';
import { PaymentDialog } from 'components/PaymentDialog/PaymentDialog';
import { snack } from 'components/Snack/Snack';
import TourPackageCard from 'components/TourPackageCard/TourPackageCard';
import { diffInDays, toDate, toFullDateName } from 'helpers/dateHelper';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import bookingsService from 'services/admin/bookingsService';
import './booking.scss'
import paymentsService from 'services/admin/paymentsService';
import { PaymentCard } from 'components/PaymentCard';

const BookingPayments = ({
    router: {
        navigate,
        params: { id },
    },
    data: {
        booking,
        tourPackages,
    },
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const onSubmit = async (formData) => {
        try {
            const formValues = Object.fromEntries(formData)
            console.log(`formValues:`, formValues);
            if (formValues.id) {
                await paymentsService.put(formValues.id, { bookingID: id })
            } else {
                await paymentsService.post(formValues, { bookingID: id })

                snack('Платеж успешно добавлен!')

                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (ex) {
            console.error(ex)
            snack(`При добавление платежа вышли ошибка: ${ex?.message}`)
        } finally {
            setIsOpen(false)
        }
    }

    return (
        <div className='m-b-64'>
            <Alert color='info' className='m-b-32'>
                <strong>Важно помнить:</strong> История платежей — это запись всех финансовых операций, связанных с оплатой конкретного туристического заказа. Она используется для отслеживания состояния платежей, управления финансами и поддержки клиентов. Ведение истории платежей помогает туристическим компаниям контролировать процессы оплаты, решать возможные спорные ситуации и предоставлять клиентам прозрачную информацию о сделанных транзакциях.
            </Alert>
            <Alert className='m-b-32' color='info'>
                Вот основные статусы по платежам:
                <ul>
                    <li>
                        <strong>В обработке</strong> - платёж находится в процессе обработки.
                    </li>
                    <li>
                        <strong>Успешно выполнен</strong>.- платёж успешно завершён, средства получены
                    </li>
                    <li>
                        <strong>Ошибка</strong>.Платеж не удался из-за технических проблем или недостатка средств на счете клиента. Необходимо повторить попытку оплаты.
                    </li>
                </ul>
            </Alert>
            <div>
                {/* <Card className='p-16 m-b-32'>
          <CardHeader title="История платежей"></CardHeader>
          <CardContent>
            {booking.payments.map(p => {
              return <div className='m-b-16'>
                <div>Сумма: {p.amount}{p.currency}. </div>
                <div>Способ оплаты: {p.paymentMethod}</div>
                <div>Дата оплаты: {toDate(p.createdAt)}</div>
              </div>
            })}
          </CardContent>
          <CardActions>
            {booking.bookingStatusID === 'created' ? <Button onClick={() => { setIsOpen(true) }}>Добавить оплату</Button> : null}
          </CardActions>
        </Card> */}
                <PaymentCard booking={booking} onClose={() => { setIsOpen(true) }}></PaymentCard>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Данные о заказе"></CardHeader>
                    <CardContent>
                        <div className='flex gap-8'>
                            <div className='profile' style={{ flex: 0.7 }}>
                                <div className="m-b-16">
                                    <strong>Идентификатор заказа: </strong>
                                    <span>{booking.id}</span>
                                </div>
                                <div className="m-b-16">
                                    <strong>Дата создания заказа: </strong>
                                    <span>{booking.createdAt ? toDate(booking.createdAt) : 'Отсутсвует'}</span>
                                </div>
                                <div className="m-b-16">
                                    <strong>Дата последнего изменения: </strong>
                                    <span>{booking.updatedAt ? toDate(booking.updatedAt) : 'Отсутсвует'}</span>
                                </div>
                                <div className="m-b-16">
                                    <strong>Заказ оформлен сотрудником: </strong>
                                    <span>{booking.createdByMember?.name ? <Link className="color-blue" target="_blank" to={`/admin/members/view/${booking.createdByMember.id}`}>{booking.createdByMember?.name}</Link> : 'Отсутсвует'}</span>
                                </div>
                                <div className="m-b-16">
                                    <strong>Статус заказа: </strong>
                                    <BookingStatus bookingStatus={booking.bookingStatus}></BookingStatus>
                                </div>
                                <div className="m-b-16">
                                    <strong>Общая сумма к оплате: </strong>
                                    <span>{booking.totalPrice}$</span>
                                </div>
                                {/* <div className="m-b-16">
                  <strong>Внесенная сумма: </strong>
                  <span>0</span>
                </div> */}
                                <div className="m-t-16 gap-2 flex">
                                    <strong>Оплатить до:</strong>
                                    <span>{toFullDateName(booking.tourPackage.startDate)}</span>
                                    <strong className='color-green'>(осталось {diffInDays(booking.tourPackage?.startDate, new Date())} дней)</strong>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Выбранный турпакет"></CardHeader>
                    <CardContent>
                        <TourPackageCard tourPackage={booking.tourPackage} options={{ cssClass: 'card-reset', hideButtons: true }}></TourPackageCard>
                    </CardContent>
                </Card>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Список туристов"></CardHeader>
                    <CardContent>
                        <div className='m-t-8 m-b-16'>
                            {booking.customers.map(c => {
                                return <div key={c.id}>
                                    <Link to={`/admin/customers/view/${c.id}`}>
                                        <strong>{c.name || c.ID}</strong>
                                    </Link>
                                </div>
                            })}
                        </div>
                    </CardContent>
                </Card>
            </div>
            <Dialog open={isOpen} onClose={() => { setIsOpen(false) }} className='p-16 w-100' fullWidth maxWidth="sm">
                <PaymentDialog onSubmit={onSubmit}></PaymentDialog>
            </Dialog>
        </div>
    )
}


export default withRouter(
    withRemoteDataAndSpinner(BookingPayments, (router) => {
        let requests = {}
        if (router.params.id) {
            requests['booking'] = () => bookingsService.get(router.params.id)
        }
        return useLoadAll(requests);
    })
);