import { countriesRoutes } from "./Countries/countries-routes";
import { organizationsRoutes } from "./Organizations/organization-routes";
import SuperAdminLayout from "./SuperAdminLayout";

export const superAdminRoutes = [
  {
    path: "superadmin",
    element: SuperAdminLayout,
    children: [
      ...organizationsRoutes,
      ...countriesRoutes
    ],
  },
];
