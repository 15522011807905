import { Outlet } from "react-router-dom";
import ListPage from "./pages/CustomerList/CustomerList";
import AddBulk from "./pages/CustomersAddBulk/CustomersAddBulk";
import Customer from "./pages/CustomerView/CustomerView";
import CustomerForm from "./pages/CustomerForm/CustomerForm";

export const adminCustomersRoutes = [
  {
    path: "customers",
    element: Outlet,
    children: [
      {
        path: "",
        element: ListPage,
      },
      {
        path: "add",
        element: CustomerForm,
      },
      {
        path: "edit/:id",
        element: CustomerForm,
      },
      {
        path: "view/:id",
        element: Customer,
      },
      {
        path: "add-bulk",
        element: AddBulk,
      }
    ],
  },
];
