import React from "react";
import { Link } from "react-router-dom";
import "./logo.scss";
import { useSelector } from "react-redux";
import { getOrg } from "redux/selectors/getOrg";
import _ from 'lodash'

const Logo = () => {
  const member = useSelector(getOrg);
  const org = _.get(member, 'org')

  return <div className="logo">
    <Link to="/" className="gap-1">
      {org?.logo && <img src={org.logo || '/media/images/img3.jpg'} height="50px" style={{ width: 'auto' }} />}
      <span className="logo-label">{org?.name || 'Hajj Travel'} </span>
    </Link>
  </div>
}
export default Logo;
