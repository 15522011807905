// import { Chart } from "chart.js";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useRemoteResource } from "hooks/useRemoteResource";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import bookingsService from "services/admin/bookingsService";

const getLastSixMonths = () => {
  let lastSixMonths = [];
  for (let i = 0; i < 6; i++) {
    lastSixMonths.push(moment().subtract(i, 'months').format('MMMM'));
  }

  return lastSixMonths
}

const LineChart = ({ data: lastMonth }) => {
  console.log(`lastMonth:`, lastMonth);
  
  useEffect(() => {
    const labels = getLastSixMonths().reverse()
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Заказы",
          data: [0, 0, 0, 0, 0, lastMonth],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    };

    const ctx = document.getElementById("lineChart");
    new window.Chart(ctx, {
      type: "line",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Количество заказов",
          },
        },
      },
    });
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <canvas id="lineChart"></canvas>
    </div>
  );
};

export default withRemoteDataAndSpinner(LineChart, () =>
  useRemoteResource(bookingsService.count)
);

