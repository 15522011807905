import { Card, CardContent } from "@mui/material";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useRemoteResource } from "hooks/useRemoteResource";
import { MdGroup } from "react-icons/md";
import { statsAdminService } from "services/admin/statsService";

const CustomersCount = ({ data }) => {
  return (
    <Card style={{ backgroundColor: 'var(--card-background-color)' }}>
      <CardContent>
        <div className="text-center" style={{ padding: 30 }}>
          <MdGroup size="48" style={{ color: 'rgba(83, 155, 255)' }}></MdGroup>
          <h3 className="text-info">Количество клиентов</h3>
          <h4 className="text-info mt-1">{data || 0}</h4>
        </div>
      </CardContent>
    </Card>
  );
};

export default withRemoteDataAndSpinner(CustomersCount, () =>
  useRemoteResource(statsAdminService.getCustomersCount)
);
