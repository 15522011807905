import { Outlet } from "react-router-dom";
import BookintList from "./BookingList";
import BookingForm from "./BookingForm";
import BookingPayments from "./BookingPayments";
import BookingDetails from "./BookingDetails";

export const adminBookingRoutes = [
  {
    path: "bookings",
    element: Outlet,
    children: [
      {
        path: "",
        element: BookintList,
      },
      {
        path: "add",
        element: BookingForm,
      },
      {
        path: "edit/:id",
        element: BookingForm,
      },
      {
        path: "view/:id",
        element: BookingDetails,
      },
      {
        path: ":id/payments",
        element: BookingPayments,
      },
    ],
  },
];
