import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import { toDate } from 'helpers/dateHelper'
import _ from 'lodash'

export const PaymentCard = ({ booking, onClose, options }) => {
    const hideButtons = _.get(options, 'hideButtons')
    return (
        <Card className='p-16 m-b-32'>
            <CardHeader title="История платежей"></CardHeader>
            <CardContent>
                {booking.payments.map(p => {
                    return <div className='m-b-16'>
                        <div>Сумма: {p.amount}{p.currency}. </div>
                        <div>Способ оплаты: {p.paymentMethod}</div>
                        <div>Дата оплаты: {toDate(p.createdAt)}</div>
                        <div>Заметка: {p.comment}</div>
                    </div>
                })}
            </CardContent>
            {hideButtons ? null : <CardActions>
                {booking.bookingStatusID === 'created' ? <Button onClick={onClose}>Добавить оплату</Button> : null}
            </CardActions>}
        </Card>
    )
}
