import { useSelector } from "react-redux";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import "./admin.scss";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import { withUser } from "hoc/withUser";
import UserMenu from "components/UserMenu/UserMenu";
import { getAuthUser } from "redux/selectors/getUser";
import Logo from "components/Logo/Logo";
import {
  MdOutlinePlayLesson,
  MdSupervisorAccount,
  MdOutlineGroups,
  MdOutlineModeComment,
  MdLogout,
  MdAirplaneTicket,
  MdSettings,
  MdAirlineSeatFlat,
  MdAirlineSeatFlatAngled,
  MdOutlineAirplaneTicket,
  MdLocalActivity,
  MdBookmarks,
  MdHotel,
  MdPayment,
  MdPayments,
  MdBarChart,
} from "react-icons/md";
import { useEffect, useRef } from "react";
import { IconButton } from "@mui/material";
import { FaBars } from "react-icons/fa";

const AdminLayout = () => {  
  const authUser = useSelector(getAuthUser);
  const sidebar = useRef(null);
  const burger = useRef(null);
  const location = useLocation();

  const adminMenu = [
    {
      icon: MdBarChart,
      path: "stats",
      label: "Аналитика",
    },
    {
      icon: MdSupervisorAccount,
      path: "customers",
      label: "Клиенты",
    },
    {
      icon: MdHotel,
      path: "hotels",
      label: "Отели",
    },
    {
      icon: MdOutlineAirplaneTicket,
      path: "airlines",
      label: "Авиакомпании",
    },
    {
      icon: MdAirplaneTicket,
      path: "flights",
      label: "Рейсы",
    },
    {
      icon: MdBookmarks,
      path: "attributes",
      label: "Атрибуты турпакета",
    },
    {
      icon: MdLocalActivity,
      path: "tour-packages",
      label: "Турпакеты",
    },
    {
      icon: MdPayments,
      path: "bookings",
      label: "Управление заказами",
    },
    {
      icon: MdAirplaneTicket,
      path: "trips",
      label: "Поездки"
    },
  ];

  const advancedAdminMenu = [
    {
      icon: MdSettings,
      path: "orgs/:id/edit".replace(':id', localStorage.getItem('orgID')),
      label: "Настройки",
    },
    {
      icon: MdOutlineGroups,
      path: "invoices",
      label: "Счет на оплату",
    },
    {
      icon: MdSupervisorAccount,
      path: "members",
      label: "Сотрудники",
    },
    {
      icon: MdSupervisorAccount,
      path: "debtors",
      label: "Реестр должникиов",
    },
    {
      icon: MdSupervisorAccount,
      path: "whatsapp/connect",
      label: "Whatsapp Интергация",
    },
    {
      icon: MdOutlineModeComment,
      path: "comments",
      label: "Обратная связь",
    },
    {
      icon: MdLogout,
      path: "/auth/logout",
      label: "Выйти",
    },
  ];

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (!sidebar.current) {
        return;
      }
      if (!sidebar.current.contains(event.target) && !burger.current.contains(event.target)) {
        sidebar.current.classList.remove("active");
      }
    });
  }, []);

  useEffect(() => {
    sidebar.current.classList.remove("active");
  }, [location]);

  return (
    <div className="page-wrapper" id="main-wrapper">
      <aside className="left-sidebar admin-nav" ref={sidebar}>
        <div className="admin-logo flex items-center">
          <Logo />
        </div>

        <nav className="sidebar-nav">
          {adminMenu.map((item, inx) => {
            return (
              <NavLink key={inx} to={item.path} relative={true} className="sidebar-link">
                <div>
                  {item.icon && <item.icon />}
                  {item.label}
                </div>
                {item.children && (
                  <div className="submenu">
                    {item.children.map((si, siIndex) => {
                      return (
                        <NavLink key={siIndex} to={si.path} relative={true} className="sidebar-link">
                          <div>
                            {si.icon && <item.icon />}
                            {si.label}
                          </div>
                        </NavLink>
                      );
                    })}
                  </div>
                )}
              </NavLink>
            );
          })}
          <hr />
          {advancedAdminMenu.map((item, inx) => {
            return (
              <NavLink key={inx} to={item.path} relative={true} className="sidebar-link">
                <div>
                  {item.icon && <item.icon />}
                  {item.label}
                </div>
              </NavLink>
            );
          })}
          <a href="https://docs.google.com/document/d/1JtrZNRnJWAdPcpsFSjtiNmvvUQ_aqiy8Adkcp6lhYuo/edit?usp=sharing" className="sidebar-link" target="_blank">
            <div>
              <MdOutlineModeComment />
              <div>Wiki</div>
            </div>
          </a>
        </nav>
      </aside>
      <section className="admin-content">
        <div className="ml-auto w-max flex">
          <UserMenu
            user={authUser}
            setMobileOpen={() => {
              sidebar.current.classList.toggle("active");
            }}
          />
          <IconButton
            ref={burger}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              sidebar.current.classList.toggle("active");
            }}
            sx={{ display: { sm: "none" } }}
          >
            <FaBars></FaBars>
          </IconButton>
        </div>
        <Breadcrumb />
        <Outlet />
      </section>
    </div>
  );
};

export default withUser(AdminLayout);
