import { Alert, Button, Card, CardActions, CardContent, CardHeader, Dialog, MenuItem } from '@mui/material';
import { BookingStatus } from 'components/BookingStatus';
import { PaymentDialog } from 'components/PaymentDialog/PaymentDialog';
import { snack } from 'components/Snack/Snack';
import TourPackageCard from 'components/TourPackageCard/TourPackageCard';
import { diffInDays, toDate, toFullDateName } from 'helpers/dateHelper';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import bookingsService from 'services/admin/bookingsService';
import './booking.scss'
import { PaymentCard } from 'components/PaymentCard';

const BookingDetails = ({
    router: {
        navigate,
        params: { id },
    },
    data: {
        booking,
        tourPackages,
    },
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const onSubmit = () => {
        setIsOpen(false)
    }

    const completeOrder = async () => {
        try {
            if (booking) {
                const { data } = await bookingsService.completeOrder(booking.id)
                snack(data.message)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (ex) {
            console.error('cancelOrder ex:', ex)
            snack(`Во время отмены заказа вышла ошибка: ${ex?.message}`)
        }
    }

    const cancelOrder = async () => {
        try {
            if (booking) {
                const { data } = await bookingsService.cancelOrder(booking.id)
                snack(data.message)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (ex) {
            console.error('cancelOrder ex:', ex)
            snack(`Во время отмены заказа вышла ошибка: ${ex?.message}`)
        }
    }
    const refundOrder = async () => {
        try {
            if (booking) {
                const { data } = await bookingsService.refundOrder(booking.id)
                snack(data.message)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (ex) {
            console.error('cancelOrder ex:', ex)
            snack(`Во время возврата заказа вышла ошибка: ${ex?.message}`)
        }
    }

    return (
        <div className='m-b-64'>
            <div>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Данные о заказе"></CardHeader>
                    <CardContent>
                        <div className='flex gap-8'>
                            <div className='profile' style={{ flex: 0.7 }}>
                                <div className="m-b-16">
                                    <strong>Идентификатор заказа: </strong>
                                    <span>{booking.id}</span>
                                </div>
                                <div className="m-b-16">
                                    <strong>Дата создания заказа: </strong>
                                    <span>{booking.createdAt ? toDate(booking.createdAt) : 'Отсутсвует'}</span>
                                </div>
                                <div className="m-b-16">
                                    <strong>Дата последнего изменения: </strong>
                                    <span>{booking.updatedAt ? toDate(booking.updatedAt) : 'Отсутсвует'}</span>
                                </div>
                                <div className="m-b-16">
                                    <strong>Заказ оформлен сотрудником: </strong>
                                    <span>{booking.createdByMember?.name ? <Link className="color-blue" target="_blank" to={`/admin/members/view/${booking.createdByMember.id}`}>{booking.createdByMember?.name}</Link> : 'Отсутсвует'}</span>
                                </div>
                                <div className="m-b-16">
                                    <strong>Статус заказа: </strong>
                                    <BookingStatus bookingStatus={booking.bookingStatus}></BookingStatus>
                                </div>
                                <div className="m-b-16">
                                    <strong>Общая сумма к оплате: </strong>
                                    <span>{booking.totalPrice}$</span>
                                </div>
                                {/* <div className="m-b-16">
                                    <strong>Внесенная сумма: </strong>
                                    <span>0</span>
                                </div> */}
                                <div className="m-t-16 gap-2 flex">
                                    <strong>Оплатить до:</strong>
                                    <span>{toFullDateName(booking.tourPackage.startDate)}</span>
                                    <strong className='color-green'>(осталось {diffInDays(booking.tourPackage?.startDate, new Date())} дней)</strong>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Выбранный турпакет"></CardHeader>
                    <CardContent>
                        <TourPackageCard tourPackage={booking.tourPackage} options={{ cssClass: 'card-reset', hideButtons: true }}></TourPackageCard>
                    </CardContent>
                </Card>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Список туристов"></CardHeader>
                    <CardContent>
                        <div className='m-t-8 m-b-16'>
                            {booking.customers.map(c => {
                                return <div key={c.id}>
                                    <Link to={`/admin/customers/view/${c.id}`}>
                                        <strong>{c.name || c.ID}</strong>
                                    </Link>
                                </div>
                            })}
                        </div>
                    </CardContent>
                </Card>
                <PaymentCard booking={booking} options={{hideButtons: true}}></PaymentCard>

                <Alert className='m-t-32 m-b-32' color='info'>
                    <div className='m-b-16'><strong>Обратите внимание: </strong>Заказы могут быть находиться в следующих статусах:</div>
                    <ul className=''>
                        <li>
                            <strong>Ожидание оплаты</strong>: Система ожидает, когда клиент произведет оплату. Это может происходить через различные методы оплаты, такие как кредитные карты, электронные кошельки, банковские переводы и т.д.
                        </li>
                        <li>Статус <strong>"Завершен"</strong>. Заказ считается завершенным только после того, как вся сумма была оплачена (либо полная оплата, либо все предоплаты были получены и покрывают общую сумму). Заказ больше не требует дальнейших действий со стороны бизнеса или клиента, и все обязательства выполнены.</li>
                        <li><strong>Отменен.</strong> Заказ отменен по какой-либо причине.</li>
                        <li>Примечание: После завершения, оформление возврата или отмены заказа вы больше не можете редактировать заказ!</li>
                    </ul>
                </Alert>
                {['created', 'partialPayment'].includes(booking.bookingStatusID) ?
                    <div className='flex gap-16'>
                        <Button type="button" variant="contained" onClick={completeOrder} color='success'>Завершить заказ</Button>
                        <Button type="button" variant="outlined" onClick={refundOrder} color='error'>Оформить воврат</Button>
                        <Button type="button" variant="outlined" onClick={cancelOrder} color='error'>Отменить заказ</Button>
                    </div> : null
                }
            </div>
            <Dialog open={isOpen} onClose={() => { setIsOpen(false) }} className='p-16 w-100' fullWidth maxWidth="sm">
                <PaymentDialog onSubmit={onSubmit}></PaymentDialog>
            </Dialog>
        </div>
    )
}

export default withRouter(
    withRemoteDataAndSpinner(BookingDetails, (router) => {
        let requests = {}
        if (router.params.id) {
            requests['booking'] = () => bookingsService.get(router.params.id)
        }
        return useLoadAll(requests);
    })
);