import { Button, Card, CardActions, CardContent } from '@mui/material'
import { createMarkup } from 'helpers/componentUtils';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Link } from 'react-router-dom';
import _ from 'lodash'
import { snack } from 'components/Snack/Snack';
import attributesService from 'services/admin/attributesService';

export default function AttributeCard({ attribute, options, loadData }) {
    const hideButtons = _.get(options, 'hideButtons')
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить атрибут?')

            if (isConfirmed) {
                await attributesService.delete(item.id);
                snack('Атрибут успешно удален!')
                if (loadData) {
                    loadData()
                }
            }
        } catch (ex) {
            console.error('При удаление атрибута вышла ошибка:', ex?.message)
        }
    };
    return (
        <div>
            <Card className='p-16'>
                <CardContent>
                    <div className='flex gap-8'>
                        {attribute.photo ? <div style={{ borderRadius: '8px', overflow: 'hidden', flex: 0.1 }}>
                            <PhotoProvider>
                                <PhotoView src={attribute.photo}>
                                    <img style={{ width: '100%' }} alt='' src={attribute.photo} />
                                </PhotoView>
                            </PhotoProvider>
                        </div> : ''
                        }
                        <div className='profile' style={{ flex: 0.7 }}>
                            <h3 className='m-b-8'>{attribute.name}</h3>
                            {attribute.price > 0 ? <div className='m-t-8'>Цена: `${attribute.price} тг.` </div> : ''}
                            <div className='m-t-8'>Статус: {attribute.status === 'active' ? 'Активный' : 'Неактивный'}</div>
                            <div className='m-t-16 fs-14' dangerouslySetInnerHTML={createMarkup(attribute.description)}></div>
                        </div>
                    </div>
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    <Link to={`/admin/attributes/edit/${attribute.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                    <Link onClick={() => onDelete(attribute)}>
                        <Button size="small">Удалить</Button>
                    </Link>
                </CardActions>}
            </Card>
        </div>
    )
}
