import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import tourPackagesService from "services/admin/tour-packages.service";
import attributesService from "services/admin/attributesService";
import TourPackageCard from "components/TourPackageCard/TourPackageCard";
import AttributeCard from "components/AttributeCard/AttributeCard";
import hotelsService from "services/admin/hotelsService";
import HotelShortCard from "components/HotelShortCard/HotelShortCard";
import flightsService from "services/admin/flightsService";
import FlightCard from "components/FlightCard/FlightCard";

function TourPackageView({
    router: {
        navigate,
        params: { id },
    },
    data: { tourPackage, attributes, hotels, flights },
}) {
    if (!attributes || !attributes.data) {
        return <div>Справочник атрибутов отсутвуют</div>
    }
    attributes = Object.fromEntries(attributes.data.map(a => [a.id, a]))
    console.log("attributes:", attributes);
    hotels = Object.fromEntries(hotels.data.map(h => [h.id, h]))
    flights = Object.fromEntries(flights.data.map(f => [f.id, f]))
    console.log("hotels:", hotels);
    console.log("flights:", flights);


    return (
        <div>
            <TourPackageCard tourPackage={tourPackage}></TourPackageCard>

            <h3 className="m-t-32 m-b-16">Перелеты туда</h3>
            {tourPackage.flightTo && flights ? <div>
                {Object.entries(tourPackage.flightTo).filter(([flightID, selected]) => flightID && ["on", 'true'].includes(selected)).map(([flightID, selected], inx) => {
                    return <FlightCard key={inx} flight={flights[flightID]} options={{ hideButtons: true }}></FlightCard>
                })}
            </div> : <div>Рейсы отсутсвуют</div>}

            <h3 className="m-t-32 m-b-16">Перелеты обратно</h3>
            {tourPackage.returnFlight && flights ? <div>
                {Object.entries(tourPackage.returnFlight).filter(([flightID, selected]) => flightID && ["on", 'true'].includes(selected)).map(([flightID, selected], inx) => {
                    return <FlightCard key={inx} flight={flights[flightID]} options={{ hideButtons: true }}></FlightCard>
                })}
            </div> : <div>Рейсы отсутсвуют</div>}

            <h3 className="m-t-32 m-b-16">Проживание</h3>
            {tourPackage.hotels && hotels ? <div>
                {Object.entries(tourPackage.hotels).filter(([hotelID, hotelSelected]) => ["on", 'true'].includes(hotelSelected)).map(([hotelID, hotelSelected], inx) => {
                    return <HotelShortCard key={inx} hotel={hotels[hotelID]} options={{ hideButtons: true }}></HotelShortCard>
                })}
            </div> : <div>Отели отсутсвуют</div>}

            <h3 className="m-t-32 m-b-16">В программу входит</h3>
            {tourPackage.attributes && attributes ? <div>
                {Object.entries(tourPackage.attributes).filter(([attributeID, selected]) => ["on", 'true'].includes(selected)).map(([attributeID, selected], inx) => {
                    if (attributes[attributeID]) {
                        return <AttributeCard key={inx} attribute={attributes[attributeID]} options={{ hideButtons: true }}></AttributeCard>
                    }
                    return null
                })}
            </div> : <div>Атрибуты отсутсвуют</div>}
        </div>
    )
}


export default withRouter(
    withRemoteDataAndSpinner(TourPackageView, (router) => {
        let requests = {
            attributes: () => attributesService.getAll(),
            hotels: () => hotelsService.getAll(),
            flights: () => flightsService.getAll()
        }
        if (router.params.id) {
            requests['tourPackage'] = () => tourPackagesService.get(router.params.id)
        }
        return useLoadAll(requests);
    })
);
