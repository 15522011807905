import { adminCustomersRoutes } from "./Customers/customers-routes";
import AdminLayout from "./AdminLayout";
import Debtor from "./Customers/pages/Debtor/Debtor";
import { adminMembersRoutes } from "./Members/members-routes";
import EmptyPage from "components/EmptyPage/EmptyPage";
import OrgForm from "./Orgs/OrgForm";
import Invoices from "./Invoices/pages/Invoices";
import WhatsappConnect from "./Whatsapp/WhatsappConnect";
import { adminStatsRoutes } from "./Stats/stats-router";
import Profile from "./Profile/Profile";
import { adminHotelsRoutes } from "./Hotels/hotels-routes";
import { adminAttributesRoutes } from "./Attributes/attributes-routes";
import { adminTourPackagesRoutes } from "./TourPackages/tour-packages-routes";
import { adminAirlinesRoutes } from "./Airlines/airlines-routes";
import { adminFlightsRoutes } from "./Flights/flights-routes";
import { adminBookingRoutes } from "./Bookings/bookings-routes";

export const adminRoutes = [
  {
    path: "admin",
    element: AdminLayout,
    name: "",
    children: [
      ...adminCustomersRoutes,
      {
        element: Debtor,
        path: "debtors",
        label: "Реестр должникиов",
      },
      {
        element: EmptyPage,
        path: "orders/list",
      },
      {
        element: EmptyPage,
        path: "trips",
      },
      {
        element: EmptyPage,
        path: "stats",
      },
      {
        element: OrgForm,
        path: "orgs/:id/edit",
      },
      {
        element: Invoices,
        path: "invoices",
      },
      {
        element: EmptyPage,
        path: "comments",
      },
      {
        element: WhatsappConnect,
        path: "whatsapp/connect",
      },
      {
        element: Profile,
        path: "profile",
      },
      ...adminMembersRoutes,
      ...adminTourPackagesRoutes,
      ...adminStatsRoutes,
      ...adminHotelsRoutes,
      ...adminAttributesRoutes,
      ...adminAirlinesRoutes,
      ...adminFlightsRoutes,
      ...adminBookingRoutes
    ],
  },
];
