import { Card, CardContent } from '@mui/material'
import { HajjImage } from 'components/HajjImage/HajjImage';
import { phoneToUI } from 'hajjtravel-utils';
import { toFullDateName } from 'helpers/dateHelper';
import { NavLink } from 'react-router-dom';

export default function CustomerCard({ customer }) {
    const avatar = customer.photo || `/media/images/avatar_${customer.gender ? String(customer.gender).toLowerCase() : 'm'}.jpg`
    return (
        <div>
            <Card>
                <CardContent>
                    <div className='flex gap-8'>
                        <div style={{ width: 100, height: 100, overflow: 'hidden', borderRadius: '50%' }}>
                            <HajjImage image={avatar} />
                        </div>
                        <div className='profile'>
                            <NavLink to={`/admin/customers/view/${customer.id}`}>
                                <h3>{customer.name}</h3>
                            </NavLink>
                            {customer.users?.phone && <div className='m-t-16 color-blue'>+7{phoneToUI(customer.user.phone)}</div>}
                            <div className='m-t-8'>{customer.user.email}</div>
                            <div className='m-t-8 fs-14'>Является клиентом с {toFullDateName(customer.createdAt)}</div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
