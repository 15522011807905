import { Button, Card, CardActions, CardContent } from '@mui/material'
import { Link } from 'react-router-dom';
import _ from 'lodash'
import flightsService from 'services/admin/flightsService';
import { MdFlightTakeoff } from 'react-icons/md';
import './flight-card.scss'
import { snack } from 'components/Snack/Snack';

function FlightDirectionPreview({ direction, label }) {
    return <div>
        <div className='m-b-8'>{label}:</div>
        <div className='m-b-8'>
            <span className='fs-24 fw-500'>{direction.airportCode}</span>
        </div>
        <div className='m-b-8'>
            <span>{direction.city?.name}</span>
        </div>
        <div className='m-b-8'>
            <span>{direction.time}</span>
        </div>
    </div>
}

export default function FlightCard({ flight, options, loadData }) {
    const hideButtons = _.get(options, 'hideButtons')
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить рейс?')

            if (isConfirmed) {
                await flightsService.delete(item.id);
                snack('Рейс успешно удален!')
                loadData && loadData()

            }
        } catch (ex) {
            console.error('При удаление рейса вышла ошибка:', ex?.message)
            snack(ex?.message)
        }
    };
    return (
        <div>
            <Card>
                <CardContent>
                    <div className='flex gap-8 flex-between flight-info flexr'>
                        <FlightDirectionPreview direction={flight.departure} label={'От'}></FlightDirectionPreview>
                        {/* To */}
                        <div className='flex flex-col flex-center text-center'>
                            <MdFlightTakeoff size={40} className="boardpass-icon"></MdFlightTakeoff>
                            <div className='fs-16'>{flight.duration} минут в пути</div>
                        </div>
                        <FlightDirectionPreview direction={flight.arrival} label={`До`}></FlightDirectionPreview>

                        <div className='m-b-8'>
                            <div className='m-b-8'>
                                <img className='airline-icon' src={flight.airline.photo} alt='' />
                            </div>
                            <div>Flight No: {flight.flightNumber}</div>
                        </div>
                    </div>
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    <Link to={`/admin/flights/edit/${flight.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                    <Link onClick={() => onDelete(flight)}>
                        <Button size="small">Удалить</Button>
                    </Link>
                </CardActions>}
            </Card>
        </div>
    )
}
