import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import { snack } from "components/Snack/Snack";
import bookingsService from "services/admin/bookingsService";
import tourPackagesService from "services/admin/tour-packages.service";
import { diffInDays, toDate, toFullDateName } from "helpers/dateHelper";
import { Alert, Button, Dialog, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TourPackageCard from "components/TourPackageCard/TourPackageCard";
import { CustomersDialog } from "components/CustomersDialog/CustomersDialog";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BookingStatus } from "components/BookingStatus";

const BookingForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: {
    booking,
    tourPackages,
  },
}) => {
  const { register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted },
    watch
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tourPackageID: booking?.tourPackageID || null,
      customers: booking?.customers ? booking.customers.map(c => ({ id: c.id, name: c.name })) : [],
      totalPrice: booking?.totalPrice || 0,
      comment: booking?.comment || ""
    }
  });

  const tourPackageInput = watch(`tourPackageID`)
  const customersInput = watch(`customers`, [])
  const [isCustomersDialogVisible, setCDVisible] = useState(false)
  const [editable, setEditable] = useState(true)

  useEffect(() => {
    if (booking) {
      const edit = ['created', 'partialPayment', 'fullPayment'].includes(booking.bookingStatus.id)
      setEditable(edit)
    }
  }, [])

  const onSubmit = async (formData) => {
    if (formData.customers) {
      formData.customers = formData.customers.map(c => c.id)
    }
    console.log("formData:", formData);
    const isConfirmed = window.confirm('Вы точно хотите оформить заказ?')
    if (!isConfirmed) {
      return
    }
    if (formData['totalPrice']) {
      delete formData['totalPrice']
    }
    try {
      if (booking) {
        await bookingsService.put(id, formData)
        snack("Заказ обновлен успешно!");
      } else {
        await bookingsService.post(formData)
        snack("Заказ создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  function addCustomer(c) {
    c = { id: c.id, name: c.name }
    const prevValues = getValues('customers') || []
    const prevValuesIDs = prevValues.map(c => c.id)
    if (!prevValuesIDs.includes(c.id)) {
      prevValues.push(c)
    }
    setValue('customers', prevValues, {
      shouldValidate: true
    })
    setCDVisible(false)
    recalculateTotalPrice()
  }

  function deleteCustomer(customerID) {
    let prevValues = getValues('customers') || []
    prevValues = prevValues.filter(cID => cID !== customerID)
    setValue('customers', prevValues, {
      shouldValidate: true
    })
    recalculateTotalPrice()
  }

  function recalculateTotalPrice() {
    const customers = getValues('customers', [])
    const tourPackageID = getValues('tourPackageID')
    const tourPackage = tourPackages.data.find(t => t.id === tourPackageID)
    if (tourPackage && customers) {
      setValue('totalPrice', customers.length * tourPackage.price)
    }
  }

  useEffect(() => {
    recalculateTotalPrice()
  }, [tourPackageInput])

  /**
   * Состояние формы
  */
  return (
    <CrudPage title={booking ? 'Редактирование заказа' : 'Создание заказа'}>
      <div className="m-b-32">
        {booking ? null :
          <Alert color="info">Чтобы создать заказ, пожалуйста:
            <ul>
              <ol>1. Выберите турпакет из списка</ol>
              <ol>2. Выберите туристов из списка нажав на кнопку "Добавить туриста"</ol>
              <ol>3. При необходимости вы можете оставить заметку</ol>
              <ol>4. Проверьте данные и нажмите на кнопку создать заказ</ol>
            </ul>
          </Alert>
        }
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller control={control} name="tourPackageID" rules={{ required: true }} render={({ field }) => {
          return <FormControl fullWidth>
            <InputLabel>Выберите турпакет</InputLabel>
            <Select disabled={!editable} label="Выберите турпакет" {...field} value={field.value || ''}>
              {tourPackages ?
                tourPackages.data.map(t => {
                  return <MenuItem key={t.id} value={t.id}>{`${t.name}: (${toFullDateName(t.startDate)} - ${toFullDateName(t.endDate)})`}</MenuItem>
                })
                : []}
            </Select>
          </FormControl>
        }}></Controller>
        <div className="m-t-16">
          {tourPackageInput && tourPackages && <TourPackageCard options={{ hideButtons: true }} tourPackage={tourPackages.data.find(t => t.id === tourPackageInput)}></TourPackageCard>}
        </div>
        {booking ? <div className="m-t-32">
          <div>
            <div className="m-b-16">
              <strong>Дата создания заказа: </strong>
              <span>{booking.createdAt ? toDate(booking.createdAt) : 'Отсутсвует'}</span>
            </div>
            <div className="m-b-16">
              <strong>Дата последнего изменения: </strong>
              <span>{booking.updatedAt ? toDate(booking.updatedAt) : 'Отсутсвует'}</span>
            </div>
            <div className="m-b-16">
              <strong>Заказ оформлен сотрудником: </strong>
              <span>{booking.createdByMember?.name ? <Link className="color-blue" target="_blank" to={`/admin/members/view/${booking.createdByMember.id}`}>{booking.createdByMember?.name}</Link> : 'Отсутсвует'}</span>
            </div>
            <div>
              <strong>Статус заказа: </strong>
              <BookingStatus bookingStatus={booking.bookingStatus}></BookingStatus>
            </div>
            <div className="m-t-16 gap-2 flex">
              <strong>Оплатить до:</strong>
              <span>{toFullDateName(booking.tourPackage.startDate)}</span>
              <strong className='color-green'>(осталось {diffInDays(booking.tourPackage?.startDate, new Date())} дней)</strong>
            </div>
            <div className="m-t-16">
              <strong>Идентификатор заказа: </strong>
              <span>{booking.id}</span>
            </div>
          </div>
        </div> : <></>}
        <section className="m-t-64" style={{ display: getValues('tourPackageID') ? 'block' : 'none' }}>
          <h3>Информация о туристах:</h3>
          <div className="m-t-16">
            <Controller control={control} name="customers" rules={{ required: true }} render={({ field }) => {
              return <FormControl fullWidth style={{ display: "none" }}>
                <InputLabel>Выберите туристов</InputLabel>
                <Select label="Выберите туристов" {...field} multiple value={field.value || []}>
                  {booking?.customers ?
                    booking.customers.map(c => {
                      return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                    })
                    : []}
                </Select>
              </FormControl>
            }}></Controller>
            <div className="m-t-16">
              {customersInput?.map((c, inx) => {
                return <div key={inx} className="m-b-8 flex flex-between">
                  <div>{inx + 1}. <Link className="color-blue" to={`/admin/customers/view/${c}`} target="_blank">
                    {c.name}
                  </Link></div>
                  <Button type="button" onClick={() => deleteCustomer(c)}>Удалить</Button>
                </div>
              })}
            </div>
          </div>
          <div className="m-t-32">
            {editable ? <Button type="button" onClick={() => { setCDVisible(true) }}>Добавить туриста</Button> : null}
          </div>
          <div className="field-amout m-t-32" style={{ display: getValues('totalPrice') ? 'block' : 'none' }}>
            <h3 className="m-b-16">Общая сумма к оплате:</h3>
            <TextField {...register('totalPrice')} required={true} disabled={true} InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}></TextField>
          </div>
        </section>
        <div className="m-t-16" style={{ display: getValues('totalPrice') ? 'block' : 'none' }}>
          <div htmlFor="comment" className="m-b-8">Заметки:</div>
          <TextField {...register('comment')} id="comment" type="text" multiline rows={2} className="w-100" placeholder="Добавить заметку"></TextField>
        </div>
        <div className="m-t-64 gap-16 flex">
          {booking ? <>
            {editable ?
              <>
                <Button type="submit" variant="contained" disabled={!isValid}>Обновить заказ</Button>
              </>
              : null}
          </> : <>
            <Button type="submit" variant="contained" disabled={!isValid}>Создать заказ</Button>
          </>}
        </div>
        {/* <pre className="m-t-32">
          {JSON.stringify(watch(), null, 4)}
        </pre> */}
        {/* <pre>
          {JSON.stringify({ errors, isValid, isDirty, dirtyFields, isSubmitting, isSubmitted }, null, 4)}
        </pre> */}
      </form>
      <Dialog open={isCustomersDialogVisible} onClose={() => { setCDVisible(false) }}>
        <CustomersDialog onSelect={addCustomer} customersInput={customersInput.map(c => c.id)}></CustomersDialog>
      </Dialog>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(BookingForm, (router) => {
    let requests = {
      tourPackages: () => tourPackagesService.getAll({ limit: 100 })
    }
    if (router.params.id) {
      requests['booking'] = () => bookingsService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
