import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import flightsService from "services/admin/flightsService";
import { updateForm } from 'hajjtravel-utils'
import { useEffect, useState } from "react";
import airlinesService from "services/admin/airlinesService";
import _ from "lodash";
import { citiesService } from '../../../services/cities.service'
import { countriesService } from "services/countries.service";

const FlightForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { flight, airlines, countries, cities },
}) => {
  const [fields, setFields] = useState([])

  useEffect(() => {
    let initialFields = [
      {
        key: "airlineID",
        label: "Пожалуйтса, выберите авиакомпанию",
        required: true,
        inputType: inputTypeEnum.select,
        options: airlines?.data ? airlines.data : []
      },
      {
        key: "flightNumber",
        label: "Номер рейса",
        required: true,
        inputType: inputTypeEnum.text,
      },
      {
        label: 'Информация о вылете'
      },
      {
        key: "departure.countryID",
        label: "Страна вылета",
        required: true,
        inputType: inputTypeEnum.select,
        options: countries ? countries.data : [],
        onChange: async (countryID, fields) => {
          console.log("countryID:", countryID);

          const countryCities = cities.data.filter(x => x.countryID === countryID)
          let updatedFields = updateForm(fields, 'departure.cityID', 'options', countryCities)
          setFields(updatedFields)
        },
      },
      {
        key: "departure.cityID",
        label: "Город вылета",
        required: true,
        inputType: inputTypeEnum.select,
        options: [],
      },
      {
        key: "departure.airportCode",
        label: "Код аэропорта вылета.",
        required: true,
        inputType: inputTypeEnum.text,
      },
      {
        key: "departure.time",
        label: "Время вылета. Укажите местное время",
        required: true,
        inputType: inputTypeEnum.time,
      },
      {
        label: 'Информация о прилете'
      },
      {
        key: "arrival.countryID",
        label: "Страна прилета",
        required: true,
        inputType: inputTypeEnum.select,
        onChange: (countryID, fields) => {
          const countryCities = cities.data.filter(c => c.countryID === countryID)
          let updatedFields = updateForm(fields, 'arrival.cityID', 'options', countryCities)
          setFields(updatedFields)
        },
        options: countries ? countries.data : []
      },
      {
        key: "arrival.cityID",
        label: "Город прилета",
        required: true,
        inputType: inputTypeEnum.select,
        options: []
      },
      {
        key: "arrival.airportCode",
        label: "Код аэропорта прилета",
        required: true,
        inputType: inputTypeEnum.text,
      },
      {
        key: "arrival.time",
        label: "Время приолета. Укажите местное время",
        required: true,
        inputType: inputTypeEnum.time,
      },
      {
        key: "duration",
        label: "Длительность полета в минутах. Например: 3 часа в минутах будет 180",
        required: true,
        inputType: inputTypeEnum.number,
      },
      {
        key: 'scheduleDays',
        label: 'Расписание рейса',
        required: true,
        inputType: inputTypeEnum.chips,
        options: [
          {
            id: 'monday',
            name: 'Понидельник'
          },
          {
            id: 'tuesday',
            name: 'Вторник'
          },
          {
            id: 'wednesday',
            name: 'Среда'
          },
          {
            id: 'thursday',
            name: 'Четверг'
          },
          {
            id: 'friday',
            name: 'Пятница'
          },
          {
            id: 'saturday',
            name: 'Суббота'
          },
          {
            id: 'sunday',
            name: 'Воскресенье'
          },
        ]
      },
      {
        key: "description",
        label: "Описание",
        inputType: inputTypeEnum.richText,
      },
      {
        key: "status",
        label: "Статус",
        inputType: inputTypeEnum.select,
        defaultValue: 'active',
        options: [
          {
            id: 'active',
            name: 'Активный'
          },
          {
            id: 'inactive',
            name: 'Неактивный'
          },
        ]
      }
    ]

    if (flight) {
      // Load cities dynamically
      const departureCountryID = _.get(flight, 'departure.countryID')
      const departureCities = cities.data.filter(c => c.countryID === departureCountryID)
      initialFields = updateForm(initialFields, 'departure.cityID', 'options', departureCities)

      const arrivalCountryID = _.get(flight, 'arrival.countryID')
      const arrivalCities = cities.data.filter(c => c.countryID === arrivalCountryID)
      initialFields = updateForm(initialFields, 'arrival.cityID', 'options', arrivalCities)


      setFields(initialFields)
    } else {
      setFields(initialFields)
    }
  }, [])


  const onSubmit = async (formData) => {
    console.log("formData:", Object.fromEntries(formData));
    try {
      if (flight) {
        await flightsService.put(id, formData)
        snack("Рейс обновлен успешно!");
      } else {
        await flightsService.post(formData)
        snack("Рейс создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={flight ? 'Редактирование рейса' : 'Создание рейса'}>
      <p className="m-b-32">Перед созданием: Пожалуйста для удобства скопируйте данные из <strong>пасадочного талона</strong></p>
      <AppForm onSubmit={onSubmit} data={flight} fields={fields}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(FlightForm, (router) => {
    let requests = {
      airlines: () => airlinesService.getAll(),
      cities: () => citiesService.getAll(),
      countries: () => countriesService.getAll()
    }
    if (router.params.id) {
      requests['flight'] = () => flightsService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
