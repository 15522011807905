import { Card } from "@mui/material";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useRemoteResource } from "hooks/useRemoteResource";
import { MdListAlt } from "react-icons/md";
import bookingsService from "services/admin/bookingsService";

const OrdersCount = ({ data }) => {
  return (
    <Card style={{ backgroundColor: 'var(--card-background-color)' }}>
      <div className="text-center" style={{ padding: 30 }}>
        <MdListAlt size="48" style={{ color: 'rgba(83, 155, 255)' }}></MdListAlt>
        <h3 className="text-info">Количество заказов</h3>
        <h4 className="text-info mt-1">{data || 0}</h4>
      </div>
    </Card>
  );
};

export default withRemoteDataAndSpinner(OrdersCount, () =>
  useRemoteResource(bookingsService.count)
);
