import { Card } from "@mui/material";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useRemoteResource } from "hooks/useRemoteResource";
import { MdManageAccounts } from "react-icons/md";
import { statsAdminService } from "services/admin/statsService";

const MembersCount = ({ data }) => {
  return (
    <Card style={{ backgroundColor: 'var(--card-background-color)' }}>
      <div className="text-center" style={{ padding: 30 }}>
        <MdManageAccounts size="48" style={{ color: 'rgba(83, 155, 255)' }}></MdManageAccounts>
        <h3 className="text-info">Количество сотрудников</h3>
        <h4 className="text-info mt-1">{data || 0}</h4>
      </div>
    </Card>
  );
};

export default withRemoteDataAndSpinner(MembersCount, () =>
  useRemoteResource(statsAdminService.getMembersCount)
);
