import "./stats.scss";
import UsersCount from "../../components/UsersCount/UsersCount";
import PieChart from "../../components/PieChart/PieChart";
import LineChart from "../../components/LineChart/LineChart";
import MembersCount from "../../components/MembersCount/MembersCount";
import OrdersCount from "../../components/OrdersCount/OrdersCount";

const MainStatsPage = () => {
  return (
    <div>
      <div className="flexr stats" style={{ gap: "30px" }}>
        <UsersCount />
        <MembersCount />
        <OrdersCount />
      </div>
      <section className="m-t-32 m-b-32 flexr">
        <PieChart />
        <LineChart />
      </section>
    </div>
  );
};

export default MainStatsPage;
