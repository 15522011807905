import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import tourPackagesService from "services/admin/tour-packages.service";
import attributesService from "services/admin/attributesService";
import { useEffect, useState } from "react";
import AttributeCard from "components/AttributeCard/AttributeCard";
import { snack } from "components/Snack/Snack";
import hotelsService from "services/admin/hotelsService";
import HotelShortCard from "components/HotelShortCard/HotelShortCard";
import flightsService from "services/admin/flightsService";
import FlightCard from "components/FlightCard/FlightCard";

const TourPackageForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { tourPackage, attributes, hotels, flights },
}) => {
  console.log("tourPackage:", tourPackage);
  const [tourPackageInputs, setTourPackageInputs] = useState([])

  useEffect(() => {
    let attributesInputs = []
    if (attributes && attributes.data.length > 0) {
      attributes.data.forEach(a => {
        attributesInputs.push({
          key: `attributes.${a.id}`,
          label: () => <AttributeCard attribute={a} options={{ hideButtons: true }}></AttributeCard>,
          inputType: inputTypeEnum.checkbox,
        },)
      })
      console.log("attributesInputs:", attributesInputs);
    }

    let hotelsInputs = []
    if (hotels.data) {
      hotels.data.forEach(h => {
        hotelsInputs.push({
          key: `hotels.${h.id}`,
          label: () => <HotelShortCard hotel={h} options={{ hideButtons: true }}></HotelShortCard>,
          inputType: inputTypeEnum.checkbox,
        },)
      })
    }

    let flightsToInputs = []
    if (flights.data) {
      flights.data.forEach(f => {
        flightsToInputs.push({
          key: `flightTo.${f.id}`,
          label: () => <FlightCard flight={f} options={{ hideButtons: true }}></FlightCard>,
          inputType: inputTypeEnum.checkbox,
        },)
      })
    }

    let returnFlightsToInputs = []
    if (flights.data) {
      flights.data.forEach(f => {
        returnFlightsToInputs.push({
          key: `returnFlight.${f.id}`,
          label: () => <FlightCard flight={f} options={{ hideButtons: true }}></FlightCard>,
          inputType: inputTypeEnum.checkbox,
        },)
      })
    }

    const formInputs = [
      {
        key: "photo",
        label: "Фото турпакета",
        inputType: inputTypeEnum.file,
      },
      {
        key: "name",
        label: "Название турпакета",
        required: true,
        inputType: inputTypeEnum.text,
      },
      {
        key: "description",
        label: "Описание",
        inputType: inputTypeEnum.richText,
      },
      {
        key: "price",
        label: "Стоимость тура(в долларах)",
        inputType: inputTypeEnum.text,
        required: true,
        defaultValue: '0'
      },
      {
        label: "Перелеты туда",
      },
      {
        key: "startDate",
        label: "Дата туда",
        inputType: inputTypeEnum.date,
        required: true,
      },
      ...flightsToInputs,
      {
        label: "Перелеты обратно",
      },      
      {
        key: "endDate",
        label: "Дата обратно",
        inputType: inputTypeEnum.date,
        required: true,
      },
      ...returnFlightsToInputs,
      {
        label: "Проживание",
      },
      ...hotelsInputs,
      {
        key: "status",
        label: "Статус",
        inputType: inputTypeEnum.select,
        defaultValue: 'active',
        options: [
          {
            id: 'active',
            name: 'Активный'
          },
          {
            id: 'inactive',
            name: 'Неактивный'
          },
        ]
      },
      {
        label: 'Пожалуйста, выберите подходящие атрибуты из списка'
      },
      ...attributesInputs
    ]
    setTourPackageInputs(formInputs)
  }, [])

  const onSubmit = async (formData) => {
    console.log('Submit: ', Object.fromEntries(formData))
    try {
      if (tourPackage) {
        await tourPackagesService.put(id, formData)
        snack("Турпакет обновлен успешно!");
        setTimeout(() => {
          navigate(`/admin/tour-packages/view/${tourPackage.id}`);
        }, 500);
      } else {
        const { data: createdTourPackage } = await tourPackagesService.post(formData)
        console.log("createdTourPackage:", createdTourPackage);

        snack("Турпакет создан успешно!");
        setTimeout(() => {
          navigate(`/admin/tour-packages/view/${createdTourPackage.id}`);
        }, 500);
      }
    } catch (ex) {
      console.error(ex)
      snack(ex?.message)
    }
  };

  return (
    <CrudPage title={tourPackage ? 'Редактирование турпакета' : 'Создание турпакета'}>
      <AppForm onSubmit={onSubmit} data={tourPackage} fields={tourPackageInputs}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(TourPackageForm, (router) => {
    let requests = {
      attributes: () => attributesService.getAll(),
      hotels: () => hotelsService.getAll(),
      flights: () => flightsService.getAll()
    }
    if (router.params.id) {
      requests['tourPackage'] = () => tourPackagesService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
