import AppForm from 'components/AppForm/AppForm'
import inputTypeEnum from 'enums/inputTypes'
import React from 'react'

export const PaymentDialog = ({ payment, onSubmit }) => {
    const fields = [
        {
            key: "amount",
            label: "Сумма",
            required: true,
            inputType: inputTypeEnum.text,
        },
        {
            key: "currency",
            label: "Валюта",
            required: true,
            inputType: inputTypeEnum.select,
            options: [
                {
                    id: 'USD',
                    name: 'Доллар'
                },
                {
                    id: 'KZT',
                    name: 'Тенге'
                },
                {
                    id: 'EUR',
                    name: 'Евро'
                },
                {
                    id: 'SAR',
                    name: 'Риал'
                },
                {
                    id: 'RUB',
                    name: 'Рубль'
                },
            ],
            value: 'USD'
        },
        {
            key: "paymentMethod",
            label: "Способ оплаты",
            required: true,
            inputType: inputTypeEnum.select,
            options: [
                {
                    id: 'cash',
                    name: 'Наличный'
                },
                {
                    id: 'kaspi',
                    name: 'Каспи'
                },
                {
                    id: 'bcc',
                    name: 'БЦК'
                },
            ]
        },
        // {
        //     key: "paymentStatus",
        //     label: "Статус оплаты",
        //     required: true,
        //     inputType: inputTypeEnum.select,
        //     value: 'completed',
        //     options: [
        //         {
        //             id: 'waiting',
        //             name: 'Ожидается'
        //         },
        //         {
        //             id: 'completed',
        //             name: 'Выполнен'
        //         },
        //         {
        //             id: 'canceled',
        //             name: 'Отменен'
        //         },
        //         {
        //             id: 'error',
        //             name: 'Ошибка'
        //         },
        //     ]
        // },
        // {
        //     key: 'paymentPart',
        //     label: "Тип оплаты",
        //     required: true,
        //     inputType: inputTypeEnum.select,
        //     options: [
        //         {
        //             id: 'prepayment',
        //             name: 'Предоплата'
        //         },
        //         {
        //             id: 'full',
        //             name: 'Полная оплата'
        //         },
        //     ]
        // },
        {
            key: "comment",
            label: "Комментария к оплате",
            inputType: inputTypeEnum.text,
        },
    ]
    return (
        <div className='p-32'>
            <h3>Добавить платеж</h3>
            <AppForm data={payment} fields={fields} onSubmit={onSubmit}>
            </AppForm>
        </div>
    )
}
