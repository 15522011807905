import appAxios from "services/axios";
import { BaseService } from "../_baseService";

class BookingsAdminService extends BaseService<any> {
  constructor() {
    super(`bookings`);
  }

  completeOrder = async (bookingID: string) => {
    return appAxios.post(`${this.pathWithOrg()}/${bookingID}/complete`, {});
  };
  cancelOrder = async (bookingID: string) => {
    return appAxios.post(`${this.pathWithOrg()}/${bookingID}/cancel`, {});
  };

  refundOrder = async (bookingID: string) => {
    return appAxios.post(`${this.pathWithOrg()}/${bookingID}/refund`, {});
  };
  count = async () => {
    return appAxios.get(`${this.pathWithOrg()}/count`, {});
  };
}

export default new BookingsAdminService();
