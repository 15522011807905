import { Button, Card, CardActions, CardContent } from '@mui/material';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import { Link, useNavigate } from 'react-router-dom';
import customersService from 'services/admin/customersService';
import _ from 'lodash'
import { toDocumentFormat } from 'helpers/dateHelper';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import moment from 'moment';
import { phoneToUI } from 'hajjtravel-utils';

const gender = {
    'F': 'Женщина',
    'M': 'Мужщина'
}

function Customer({ data: { customer } }) {
    const navigate = useNavigate()
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить клиента?')

            if (isConfirmed) {
                await customersService.delete(item.id);
                alert('Клиент успешно удален!')
                navigate('/admin/customers')
            }
        } catch (ex) {
            console.error('При удаление клиента вышла ошибка:', ex?.message)
            alert('При удаление клиента вышла ошибка:' + ex?.message)
        }
    };

    const passport = _.get(customer, 'passport')
    const IDCard = _.get(customer, 'IDCard')

    return (
        <div>
            <Card>
                <CardContent>
                    <div className='flex gap-8'>
                        <div>
                            <img style={{ width: 100, borderRadius: '50%' }} src={customer.photo || `/media/images/avatar_${customer.gender ? String(customer.gender).toLowerCase() : 'm'}.jpg`} />
                        </div>
                        <div className='profile'>
                            <h3>{_.get(customer, 'name', 'Отсутсвует2')}</h3>
                            {customer.users?.phone && <div className='m-t-16 color-blue'>+7{phoneToUI(customer.user.phone)}</div>}
                            <div className='m-t-8'>{customer.user.email}</div>
                            <div className='m-t-8'>Дата рождения: {customer.user.birthdate ? moment(customer.user.birthdate).format('DD-MM-YYYY') : 'Отсутсвует'}</div>
                            <div className='m-t-8'>Пол: {customer.gender ? gender[customer.gender] : 'Отсутсвует'}</div>
                        </div>
                    </div>
                </CardContent>
                <CardActions className='justify-end'>
                    <Link to={`/admin/customers/edit/${customer.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                    <Link onClick={() => onDelete(customer)}>
                        <Button size="small">Удалить</Button>
                    </Link>
                </CardActions>
            </Card>
            <Card className='m-t-32'>
                <CardContent>
                    <h3 className='m-b-16'>Данные о удостоверение личности</h3>
                    <div className='m-t-8'>
                        <span>Номер: </span>
                        <span>{IDCard?.iin || 'Отсутсвует'}</span>
                    </div>
                    <div className='m-t-8'>
                        <div>Фото версия документа:</div>
                        <div>
                            {IDCard ? <div className='m-t-8' style={{ marginLeft: '' }}>
                                <PhotoProvider>
                                    {IDCard.photoFront ? <PhotoView src={IDCard.photoFront}>
                                        <div style={{ width: 200 }}>
                                            <img src={IDCard.photoFront} alt="Photo Front" />
                                        </div>
                                    </PhotoView> : <div>Фронтальная часть: Отсутсвует</div>}
                                    {IDCard.photoBack ? <PhotoView src={IDCard.photoBack}>
                                        <div style={{ width: 200 }}>
                                            <img src={IDCard.photoBack} alt="Photo Back" />
                                        </div>
                                    </PhotoView> : <div>Обратная сторона: Отсутсвует</div>}
                                </PhotoProvider>
                            </div> : <></>}
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card className='m-t-32'>
                <CardContent>
                    <h3 className='m-b-16'>Данные о паспорте</h3>
                    <div className='m-t-8'>
                        <span>Номер пасспорта: </span>
                        <span>{passport?.number || 'Отсутсвует'}</span>
                    </div>
                    <div className='m-t-8'>
                        <span>Годен до: </span>
                        <span>{toDocumentFormat(passport?.dateOfExpire) || 'Отсутсвует'}</span>
                    </div>
                    <div className='m-t-8'>
                        <span>Фото версия документа:</span>
                        <span>
                            {passport?.photo ? <div className='m-t-16' style={{ marginLeft: '-8px' }}>
                                <PhotoProvider>
                                    <PhotoView src={passport.photo}>
                                        <div style={{ width: 200 }}>
                                            <img src={passport.photo} alt="Photo of passport" />
                                        </div>
                                    </PhotoView>
                                </PhotoProvider>
                            </div> : <span> Отсутсвует</span>}
                        </span>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
export default withRouter(
    withRemoteDataAndSpinner(Customer, (router) => {
        return useLoadAll({
            customer: () => customersService.get(router.params.id),
        });
    })
);
