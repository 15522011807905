import { Button, Card, CardActions, CardContent } from '@mui/material'
import { createMarkup } from 'helpers/componentUtils';
import { Link } from 'react-router-dom';
import _ from 'lodash'
import { toOnlyDate } from 'helpers/dateHelper';
import tourPackagesService from 'services/admin/tour-packages.service';
import { snack } from 'components/Snack/Snack';
import { MdFlightTakeoff, MdOutlineHotel, MdPerson } from 'react-icons/md';
import { FaCarAlt } from 'react-icons/fa';
import moment from 'moment';
import { HajjImage } from 'components/HajjImage/HajjImage';

export default function TourPackageCard({ tourPackage, options, loadData }) {
    const hideButtons = _.get(options, 'hideButtons')    
    const cssClass = _.get(options, 'cssClass', '')    
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить турпакет?')

            if (isConfirmed) {
                await tourPackagesService.delete(item.id);
                snack('Турпакет успешно удален!')
                if (loadData) {
                    loadData()
                }
            }
        } catch (ex) {
            console.error('При удаление турпакета вышла ошибка:', ex?.message)
        }
    };
    return (
        <div className={cssClass}>
            <Card className='p-16'>
                <CardContent>
                    <div className='flexr gap-16'>
                        <div style={{ borderRadius: '8px', overflow: 'hidden', flex: 0.2 }}>
                            <HajjImage image={tourPackage.photo} />
                        </div>
                        <div className='profile' style={{ flex: 0.7 }}>
                            <Link to={`/admin/tour-packages/view/${tourPackage.id}`}>
                                <h3 className='m-b-8 fs-20'>{tourPackage.name}</h3>
                            </Link>
                            <div className='m-t-16 fs-16'>Цена: {tourPackage.price > 0 ? `${tourPackage.price}$ за человека` : 'Отсутствует'}</div>
                            {tourPackage.startDate && tourPackage.endDate ? <div className='m-t-8'>Длительность: <strong>{moment(tourPackage.endDate).diff(moment(tourPackage.startDate), 'days')} ночей</strong></div> : <></>}
                            {tourPackage.startDate && tourPackage.endDate ? <div className='m-t-8'>Период: с {toOnlyDate(tourPackage.startDate)} по {toOnlyDate(tourPackage.endDate)}</div> : <></>}
                            <div className='m-t-8'>Статус: {tourPackage.status === 'active' ? <span className='color-green'>Активный</span> : 'Неактивный'}</div>
                            <div className='m-t-16 fs-14' dangerouslySetInnerHTML={createMarkup(tourPackage.description)}></div>
                            <div className='tour-options m-t-32 fs-16 gap-4 flex flexw'>
                                {tourPackage.hotels ? <div className='flex gap-1'>
                                    <MdOutlineHotel className='fs-20 color-green'></MdOutlineHotel>
                                    <span className='fs-16'>Проживание</span>
                                </div> : <></>}
                                {tourPackage.flightTo ? <div className='flex gap-1'>
                                    <MdFlightTakeoff className='fs-20 color-green'></MdFlightTakeoff>
                                    <span className='fs-16'>Перелеты</span>
                                </div> : <></>}
                                {tourPackage.attributes ? <div className='flex gap-1'>
                                    <FaCarAlt className='fs-20 color-green'></FaCarAlt>
                                    <span className='fs-16'>Трансфер</span>
                                </div> : <></>}
                                {tourPackage.attributes ? <div className='flex gap-1'>
                                    <MdPerson className='fs-20 color-green'></MdPerson>
                                    <span className='fs-16'>Гид</span>
                                </div> : <></>}
                            </div>
                        </div>
                    </div>
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    <Link to={`/admin/tour-packages/edit/${tourPackage.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                    <Link onClick={() => onDelete(tourPackage)}>
                        <Button size="small">Удалить</Button>
                    </Link>
                </CardActions>}
            </Card>
        </div>
    )
}
