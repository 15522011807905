import { Button, Card, CardActions, CardContent } from '@mui/material'
import { createMarkup } from 'helpers/componentUtils';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash'
import airlinesService from 'services/admin/airlinesService';

export default function AirlineCard({ airline, options }) {
    const hideButtons = _.get(options, 'hideButtons')
    const navigate = useNavigate()
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить авиакомпанию?')

            if (isConfirmed) {
                await airlinesService.delete(item.id);
                alert('Авиакомпания успешно удалена!')
                navigate('/admin/airlines')
            }
        } catch (ex) {
            console.error('При удаление атрибута вышла ошибка:', ex?.message)
        }
    };
    return (
        <div>
            <Card className='p-16'>
                <CardContent>
                    <div className='flex gap-8'>
                        {airline.photo ? <div style={{ borderRadius: '8px', overflow: 'hidden', flex: 0.1 }}>
                            <PhotoProvider>
                                <PhotoView src={airline.photo}>
                                    <img style={{ width: '100%' }} alt='' src={airline.photo} />
                                </PhotoView>
                            </PhotoProvider>
                        </div> : ''
                        }
                        <div className='profile' style={{ flex: 0.7 }}>
                            <h3 className='m-b-8'>{airline.name}</h3>
                            <div className='m-t-8'>Статус: {airline.status === 'active' ? 'Активный' : 'Неактивный'}</div>
                            <div className='m-t-16 fs-14' dangerouslySetInnerHTML={createMarkup(airline.description)}></div>
                        </div>
                    </div>
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    <Link to={`/admin/airlines/edit/${airline.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                    <Link onClick={() => onDelete(airline)}>
                        <Button size="small">Удалить</Button>
                    </Link>
                </CardActions>}
            </Card>
        </div>
    )
}
