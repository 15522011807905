import { Button, Card, CardActions, CardContent } from '@mui/material'
import { createMarkup } from 'helpers/componentUtils';
import { Link } from 'react-router-dom';
import _ from 'lodash'
import bookingsService from 'services/admin/bookingsService';
import { snack } from 'components/Snack/Snack';
import { diffInDays, toDate, toFullDateName } from 'helpers/dateHelper';
import { BookingStatus } from 'components/BookingStatus';

export default function BookingCard({ booking, options, loadData }) {
    const hideButtons = _.get(options, 'hideButtons')
    const onCancel = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите отменить заказ?')

            if (isConfirmed) {
                await bookingsService.delete(item.id);
                snack('Заказ успешно оформлен!')
                loadData && loadData()
            }
        } catch (ex) {
            console.error('При отмене заказа вышла ошибка:', ex?.message)
        }
    };
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить заказ?')

            if (isConfirmed) {
                await bookingsService.delete(item.id);
                snack('Заказ успешно удален!')
                loadData && loadData()
            }
        } catch (ex) {
            console.error('При удаление заказа вышла ошибка:', ex?.message)
        }
    };
    return (
        <div>
            <Card className='p-16'>
                <CardContent>
                    <div className='flex gap-8'>
                        <div className='profile' style={{ flex: 0.7 }}>
                            <div className='m-t-8 m-b-16'>
                                <div className='m-b-8'>Туристы:</div>
                                {booking.customers.map(c => {
                                    return <div key={c.id}>
                                        <Link to={`/admin/customers/view/${c.id}`}>
                                            <strong>{c.name || c.ID}</strong>
                                        </Link>
                                    </div>
                                })}
                            </div>
                            <div className='m-t-8'>
                                Выбранный турпакет: <strong>
                                    <Link to={`/admin/tour-packages/view/${booking.tourPackageID}`}>
                                        {booking.tourPackage?.name}
                                    </Link></strong>
                            </div>
                            <div className="m-t-8 gap-2 flex">
                                <span>Сумма которую необходимо оплатить:</span>
                                <strong>{booking.totalPrice}$</strong>
                            </div>
                            <div className='m-t-8'>Статус заказа: &nbsp;
                                <BookingStatus bookingStatus={booking.bookingStatus}></BookingStatus>
                            </div>
                            <div className='m-t-8 gap-2 flex'>
                                <span>Дата создания заказа:</span>
                                <strong>{toDate(booking.createdAt)}</strong>
                            </div>
                            <div className="m-t-8 gap-2 flex">
                                <span>Оплатить до:</span>
                                <strong>{toFullDateName(booking.tourPackage.startDate)}</strong>
                                <strong className='color-green'>(осталось {diffInDays(booking.tourPackage?.startDate, new Date())} дней)</strong>
                            </div>
                            <div className='m-t-16 fs-14' dangerouslySetInnerHTML={createMarkup(booking.comment)}></div>
                        </div>
                    </div>
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    {
                        booking.bookingStatusID === 'created' ? <Link to={`/admin/bookings/edit/${booking.id}`}>
                            <Button size="small">Редактировать заказ</Button>
                        </Link> : null
                    }
                    <Link to={`/admin/bookings/${booking.id}/payments`}>
                        <Button size="small">История платежей по заказу</Button>
                    </Link>
                    <Link to={`/admin/bookings/view/${booking.id}`}>
                        <Button size="small">Детали заказа</Button>
                    </Link>
                </CardActions>}
            </Card>
        </div>
    )
}
