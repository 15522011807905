import { SuperAdminService } from "services/superAdminService";


class CountriesSAService extends SuperAdminService<any> {
  constructor() {
    super("countries");
  }
}

export default new CountriesSAService();
