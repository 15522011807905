import { toQueryParams } from "helpers/url";
import appAxios from "./axios";

export class SuperAdminService<T> {
  constructor(public basePath: string) { }

  getAll = (queryParams: object): Promise<T[]> => {
    return appAxios.get(`/superadmin/${this.basePath}?` + toQueryParams(queryParams));
  };
  get = (id: number): Promise<T> => appAxios.get(`/superadmin/${this.basePath}/${id}`);
  delete = (id: number): Promise<number> => appAxios.delete(`/superadmin/${this.basePath}/${id}`);
  post = (data: number): Promise<T> => appAxios.post(`/superadmin/${this.basePath}`, data);
  put = (id: number, data: T): Promise<T> => appAxios.put(`/superadmin/${this.basePath}/${id}`, data);
  patch = (id: number, data: T): Promise<T> => appAxios.patch(`/superadmin/${this.basePath}/${id}`, data);
}
